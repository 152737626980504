<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <v-img
          :src="appName"
          max-height="30px"
          max-width="125px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <v-img
              contain
              max-width="100%"
              class="auth-3d-group"
              :src="require(`@/assets/images/3d-characters/Welcome page4.png`)"
            />
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Welcome to Cometa Tools! 👋🏻
                  </p>
                  <p class="mb-2">
                    Please sign-up your account and start the adventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <div v-if="isRegistered">
                    <p>You have successfully registered</p>
                    <v-card-title class="pl-0">
                      Official links
                    </v-card-title>
                    <v-card-text class="pl-0">
                      <v-list class="pl-0">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>
                              {{ icons.mdiDiscord }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <a href="https://discord.com/channels/1024775090644205599/1025123349942259752/1026437377188712500">Discord</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>
                              {{ icons.mdiTwitter }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <a href="https://twitter.com/CometaTools">Twitter</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>
                              {{ icons.mdiBookOutline }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <a href="https://docs.cometa.tools/cometa-tools-guide/discord-module/get-started">Documentation</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>
                              {{ icons.mdiMapMarkerPath }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <a href="https://docs.cometa.tools/cometa-tools-guide/roadmap/cometa-tools-roadmap">Roadmap</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>
                              {{ icons.mdiYoutube }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <a href="https://www.youtube.com/channel/UCKSaamPNNZdG-4Y7RTanvhQ">YouTube</a>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </div>
                  <v-form
                    v-else
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      :disabled="loading"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                      :rules="[emailValidator]"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :disabled="loading"
                      hide-details="auto"
                      class="mb-6"
                      :rules="[passwordValidator]"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-text-field
                      v-model="passwordConfirm"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Confirm password"
                      placeholder="Confirm password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :disabled="loading"
                      hide-details="auto"
                      class="mb-6"
                      :rules="[passwordConfirmValidation(password),passwordValidator]"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-btn
                      block
                      :loading="loading"
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Register
                    </v-btn>
                  </v-form>
                  <v-btn
                    v-if="isShowBtn"
                    block
                    :loading="loading"
                    class="mt-6"
                    color="blue-grey"
                    to="/login"
                  >
                    Back to login
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { emailValidator, passwordConfirmValidation, passwordValidator } from '@core/utils/validation'
import {
  mdiEyeOutline, mdiEyeOffOutline,
  mdiDiscord, mdiTwitter, mdiBookOutline, mdiMapMarkerPath, mdiYoutube,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { ref, onMounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import SettingsApi from '@core/api/settings'

export default {
  setup(props, context) {
    const loginForm = ref(null)
    const { router } = useRouter()

    // const vm = getCurrentInstance().proxy
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const passwordConfirm = ref('')
    const isRegistered = ref(false)
    const isShowBtn = ref(true)
    const referralData = ref({})
    const loading = ref(false)

    onMounted(() => {
      if (router.history && router.history.current.query.inviteCode) {
        referralData.value.inviteCode = +router.history.current.query.inviteCode
      }
    })

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      try {
        isShowBtn.value = false
        await SettingsApi.User.registerUser({ email: email.value, password: password.value, ...referralData.value })
        isRegistered.value = true
        setTimeout(() => {
          isShowBtn.value = true
        }, 20000)
      } catch (error) {
        isShowBtn.value = true
        context.root.$message.error(error)
      } finally {
        loading.value = false
      }
    }

    return {
      emailValidator,
      loginForm,
      passwordValidator,
      isPasswordVisible,
      referralData,
      isRegistered,
      isShowBtn,
      email,
      password,
      passwordConfirm,
      loading,
      passwordConfirmValidation,
      handleFormSubmit,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDiscord,
        mdiTwitter,
        mdiBookOutline,
        mdiMapMarkerPath,
        mdiYoutube,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
